<template>
    <Breadcrumb/>
    <section class="boletins">
        <div class="container">
            <div class="row mt-5">
                <div class="boletins-box">
                    <div class="row" v-for="(boletim, index) in boletins" :key="index">
                        <div class="col-lg-4">
                            <h2>{{ boletim.titulo }}</h2>
                        </div>
                        <div class="col-lg-8">
                            <div class="box-boletim">
                                <div class="titulo">
                                    <h6>
                                        {{ boletim.nome }}
                                    </h6>
                                </div>
                                <div class="resumo-boletim">
                                    <p>
                                       {{ boletim.resumo }}
                                    </p>
                                </div>
                                <div class="link-boletim">
                                    <a :href="boletim.link" target="_blank">
                                        Acessar
                                        <i class="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-3">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterInternas/>
</template>
<script>
import FooterInternas from '@/components/FooterInternas/FooterInternas.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';

import axios from 'axios';

const URL = 'https://api.cref20.org.br/wp-json/cref/v1/boletins';
export default {
    name: 'BoletinsPage',
    components: {
        FooterInternas,
        Breadcrumb
    },
    data() {
        return {
            selected: 'todos',
            boletins: []
        }
    },
    mounted() {
        axios.get(URL).then((response) => {
            this.boletins = response.data[0].boletins.map(boletim => ({
                titulo: boletim.titulo_do_boletim,
                nome: boletim.nome_do_boletim,
                resumo: boletim.resumo_do_boletim,
                link: boletim.link_do_boletim
            }))
        })
    }
}
</script>
<style>
.boletins-box h2 {
    color: #12575F;
    font-family: 'Roboto';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.boletins-box .titulo h6 {
    color: #12575F;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.box-boletim {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
    border-radius: 10px;
}
.link-boletim a {
    color: #5e5e5e;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    border-bottom: 1px solid #5D5D5D;
}
</style>