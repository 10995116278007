<template>    
<div class="row pessoa-container mt-5">
    <div class="col-lg-6" v-for="(pessoa, index) in pessoas" :key="index">
        <router-link :to="pessoa.link_pessoa">
            <div class="layer-p"></div>
            <div class="thumb-pessoa" :style="{ backgroundImage: `url(${pessoa.backgroundImage})` }">
                <div class="titulo-pessoa">
                    <h6>
                        {{pessoa.titulo_pessoa}}
                    </h6>
                </div>
            </div>
        </router-link>
    </div>
</div>
</template>

<script>

import axios from 'axios';
const urlHOME = 'https://api.cref20.org.br/wp-json/cref/v1/home';
export default {
    name: 'TipoPessoa',
    data() {
       return {
         backgroundImage: require("@/assets/imgs/person1.png"),
         pessoas: []
        }
    },
    mounted() {
        axios.get(urlHOME).then((response) => {
            this.pessoas = response.data[0].corpo.tipo_de_pessoa.map((pessoa) => ({
                backgroundImage: pessoa.imagem_de_fundo,
                titulo_pessoa: pessoa.titulo_pessoa,
                icone_pessoa: pessoa.icone_pessoa,
                link_pessoa: pessoa.link_pessoa
            }))
        })
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
.pessoa-container .layer-p {
    width: 15%;
    height: 165px;
    background: linear-gradient(85deg, #12575F 14.17%, rgba(18, 87, 95, 0.00) 58.26%);
    border-radius: 10px;
    position: absolute
}

.pessoa-container .thumb-pessoa {
    width: 100%;
    height: 165px;
    padding: 20px;
    border-radius: 10px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
}
.thumb-pessoa .titulo-pessoa {
    position: relative;
    z-index: 10;
}
.thumb-pessoa .titulo-pessoa h6 {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
</style>