<template>
    <section class="leitura-p">
        <div class="container">
            <div class="row" v-for="(pf, index) in postLeitura" :key="index">
                <div class="col-lg-12 mt-5">
                    <div class="titulo-post text-center">
                        <h2>
                            {{ pf.titulo }}
                        </h2>
                    </div>
                </div>
                <div class="col-lg-12 mt-4">
                    <div class="texto">
                        <p v-html="pf.conteudo"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterInternasVue/>
</template>
<script>
import axios from 'axios';
import FooterInternasVue from '@/components/FooterInternas/FooterInternas.vue';

export default {
    name: 'LeituraPf',
    components: {
        FooterInternasVue
    },
    data() {
        return  {
            postLeitura: []
        }
    },
    watch: {
        $route() {
            this.fetchData();
        }
    },
    methods: {
        fetchData() {
            const PFPOST = 'https://api.cref20.org.br/wp-json/cref/v1/pessoa-fisica/' + this.$route.params.link;

            axios.get(PFPOST).then((response) => {
                this.postLeitura = response.data.map((dados) => ({
                    titulo: dados.titulo,
                    conteudo: dados.texto_conteudo
                }));
            })
        }
    },
    created() {
        this.fetchData()
    }
}
</script>

<style scoped>
    
</style>