<template>
    <section class="sobre-home mt-5">
        <svg xmlns="http://www.w3.org/2000/svg" width="1366" height="216" viewBox="0 0 1366 216" fill="none"
            preserveAspectRatio="none">
            <path d="M1366.95 0.425781H-0.179932V15.616C443.845 276.188 887.87 287.873 1366.95 15.616V0.425781Z"
                fill="white" />
        </svg>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6" v-for="(sobre, index) in sobreConteudos" :key="index">
                    <img :src="sobre.imagem_sobre" alt="Sobre Home" class="person-sobre">
                </div>

                <div class="col-lg-6">
                    <div class="container">
                        <div class="row">
                            <section class="cargos-disponiveis" id="vagas">
                                <div class="container">
                                    <h1 class="titulo-cargos mb-5">Confira os cargos disponíveis e cadastre-se</h1>
                                    <div v-if="true">
                                        <div class="row box-vagas" v-for="(vaga, index) in vagas.slice(0, 1)"
                                            :key="index">
                                            <div class="titulo-vaga">
                                                <h5>
                                                    {{ vaga.cargo }}
                                                </h5>
                                            </div>
                                            <div class="nome-vaga">
                                                <span>
                                                    {{ vaga.nome_empresa }}
                                                </span>
                                            </div>
                                            <div v-if="isExpanded[index]" class="collapse-content cargo">
                                                <div><strong>Telefone:</strong> {{ vaga.numero }}</div>
                                                <div><strong>E-mail:</strong> {{ vaga.email }}</div>
                                            </div>
                                            <button @click="toggleCollapse(index)" class="btn btn-mais p-0">
                                                {{ isExpanded[index] ? 'Ocultar informações' : 'Ver informações' }}
                                            </button>
                                        </div>
                                        <div class="text-center mt-5">
                                            <a href="vagas" class="btn btn-carregar-mais">Ver todas as vagas</a>
                                        </div>
                                    </div>

                                    <div v-else>
                                        <h3>Infelizmente ainda não temos vagas cadastradas</h3>
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <VagasPage />
    </section>
</template>

<script>
import axios from 'axios';
import VagasPage from '../Vagas/Vagas.vue';

const urlHOME = 'https://api.cref20.org.br/wp-json/cref/v1/home';
const urlVAGAS = 'https://api.cref20.org.br/wp-json/cref/v1/vagas';

export default {
    name: 'SobreHomeVue',
    components: {
        VagasPage
    },
    data() {
        return {
            sobreConteudos: [],
            vagas: [],
            isExpanded: {},
        }
    },
    mounted() {
        axios.get(urlHOME).then((response) => {
            this.sobreConteudos = response.data[0].corpo.sobre_home.map((sobre) => ({
                imagem_sobre: sobre.imagem_sobre_home,
            }));
        });
        axios.get(urlVAGAS).then((response) => {
            const data = response.data;
            this.vagas = data.map((vaga) => ({
                id_vagas: vaga.id_vaga,
                slug_vaga: vaga.slug_vaga,
                cargo: vaga.cargo,
                nome_empresa: vaga.nome_empresa,
                numero: vaga.numero_contato,
                email: vaga.email_contato
            }))
        });
    },
    methods: {
        toggleCollapse(index) {
            if (Object.hasOwn(this.isExpanded, index)) {
                this.isExpanded[index] = !this.isExpanded[index];
            } else {
                this.isExpanded[index] = true;
            }
        }
    }
}

</script>

<style scoped>
.alert {
    margin-top: 16px;
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.alert-danger {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
}

.alert strong {
    font-weight: 500;
}

.col-lg-6 img {
    border-bottom-left-radius: 779px 435px;
    border-bottom-right-radius: 779px 435px;
    height: 584px;
    object-fit: cover;
}

.sobre-home {
    background-color: #12575F;
}

.sobre-home svg {
    width: 100%;
    margin-top: -2px;
}

.sobre-home .col-lg-6 {
    margin-top: 6rem;
}

.sobre-home .person-sobre {
    width: 100%;
    object-fit: contain;
}

.sobre-home .info-sb-home .titulo-sobre h3 {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sobre-home .info-sb-home .lista-principios {
    list-style-image: url(../../assets/imgs/icon-principios.png);
}

.sobre-home .info-sb-home .lista-principios li {
    margin: 15px 0;
}

.sobre-home .info-sb-home .lista-principios li .titulo-principio {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sobre-home .info-sb-home .lista-principios li .subtitulo-principios {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sobre-home .info-sb-home .btn-sobre {
    margin-top: 40px;
}

.sobre-home .info-sb-home .btn-sobre a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #45A047;
    padding: 20px 25px;
    border-radius: 50px;
    border: 2px solid #448945;
}

.sobre-home form .col-lg-12 {
    margin-bottom: 1rem;
}

.sobre-home form .form-check-label {
    color: #fff;
}

.sobre-home form .btn-enviar {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #45A047;
    padding: 20px 25px;
    border-radius: 50px;
    border: 2px solid #448945;
}

@media (max-width: 820px) {
    .sobre-home {
        position: relative;
    }
}

@media (max-width: 765px) {
    svg {
        display: none;
    }
}

.cargos-disponiveis .titulo-cargos {
    color: #fff;
    font-weight: 600;
    margin: 0;
    font-size: 2.2rem;
}

.cargos-disponiveis .cargo {
    font-size: 1rem;
    color: #fff;
    margin: 0;
    font-weight: 700;
}

.cargos-disponiveis .box-vagas {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 15px;
    border: 1px solid #fcd2d2;
    border-radius: 10px;
    gap: 3px;
    transition: 0.25s;
}

.titulo-vaga,
.nome-vaga {
    font-size: 1rem;
    color: #fff;
    margin: 0;
    font-weight: 700;
}

.btn-carregar-mais {
    color: #fff;
    border: 2px solid #12575f;
    background-color: #45a047;
    padding: 0.4rem 2.5rem;
    text-decoration: none;
    font-weight: 700;
    border-radius: 5px;
    font-size: 16px;
    border-radius: 50px;
}

.btn-carregar-mais:hover {
    background-color: #142d30;
    color: #45a047;
}

.btn-mais {
    color: #45a047;
    border: none;
    background-color: transparent;
    padding: 0.4rem 2.5rem;
    text-decoration: none;
    font-weight: 700;
    font-size: 1rem;
}
</style>