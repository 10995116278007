<template>
<div class="container">
    <div class="newsletter">
        <div class="row align-items-center">
            <div class="box-news" v-for="(news, index) in newsletter" :key="index">
                <div class="icon">
                    <img :src="news.icone_news" alt="Ícone Newsletter">
                </div>
                <div class="titulo-news">
                    <h4>
                        {{ news.titulo_news }}
                    </h4>
                </div>
                <div class="info-geral-news">
                    <div class="titulos-news">
                        <span class="news-span-titulo">
                            {{ news.segundo_titulo }}
                        </span>
                        <br>
                        <span class="news-span-subtitulo">
                            {{ news.subtitulo_news }}
                        </span>
                    </div>
                    <div class="btn-acompanhe">
                        <router-link to="/contato" aria-label="Acompanhar">
                            Acompanhar
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';

const urlHOME = 'https://api.cref20.org.br/wp-json/cref/v1/home'; 
export default {
    name: 'NewsletterVue',
    data() {
        return {
            newsletter: []
        }
    },
    mounted() {
        axios.get(urlHOME).then((response) => {
            this.newsletter = response.data[0].corpo.newsletter.map((news) => ({
                icone_news: news.icone_news,
                titulo_news: news.titulo_news,
                segundo_titulo: news.segundo_titulo_news,
                subtitulo_news: news.subtitulo_news
            }))
        });
    }
}
</script>

<style scoped>
.newsletter {
    background-color: #184449;
    height: 15vh;
    display: flex;
    align-items: center;
    border-radius: 10px;
    position: relative;
    top: -37px;
}
.newsletter .row {
    width: 100%;
}
.newsletter .box-news {
    width: 100%;
    display: flex;
    align-items: center;
}
.newsletter .box-news .icon {
    background-color: #45A047;
    padding: 41px;
    border-radius: 0 70px 70px 0;
    position: relative;
    z-index: 10;
}
.newsletter .box-news .titulo-news {
    background: #142D30;
    padding: 46px 88px 46px;
    border-radius: 0px 70px 70px 0px;
    position: relative;
    left: -65px;
    z-index: 9;
}
.newsletter .box-news .titulo-news h4 {
    color: #fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.newsletter .box-news .info-geral-news {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
}
.newsletter .box-news .info-geral-news .titulos-news .news-span-titulo {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
}
.newsletter .box-news .info-geral-news .titulos-news .news-span-subtitulo {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.newsletter .box-news .info-geral-news .btn-acompanhe a {
    border-radius: 50px;
    border: 2px solid #448945;
    background: #45A047;
    padding: 20px 25px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
@media (max-width: 1399px) {
    .newsletter {
        height: 21vh;
    }
    .newsletter .box-news .icon {
        padding: 33px;
    }
    .newsletter .box-news .titulo-news h4 {
        font-size: 25px;
    }
}
@media (max-width: 1354px) {
	.newsletter {
		height: 20vh;
	}
	.newsletter .titulo-news {
		padding: 4px 88px 31px !important;
	}
	.newsletter .icon {
        padding: 35px !important;
	}
}
@media (max-width: 820px) {
	.newsletter {
		display: none;
	}
}
@media (max-width: 768px) {
	.newsletter {
		display: none;
	}
}
@media (max-width: 765px) {
	.newsletter {
		display: none;
	}
}
 
</style>
