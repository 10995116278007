<template>
    <BreadcrumbVue />

    <section class="vagas">
        <div class="container">
            <div class="row mt-4">
                <label for="filtroVagas">Filtrar por:</label>
                <div class="col-lg-4 mt-2">
                    <input type="text" class="form-control" id="filtroVagas" v-model="filtroSelecionado"
                        @input="atualizarLista" placeholder="Digite o cargo ou nome da empresa">
                </div>
            </div>
            <div class="row">
                <section class="vagas-disponiveis my-5" id="vagas">
                    <div class="container">
                        <h1 class="titulo-cargos mb-5">Confira os cargos disponíveis e cadastre-se</h1>

                        <div v-if="vagasFiltradas.length > 0">
                            <div class="row">
                                <div class="col-lg-4 mt-4" v-for="(vaga, index) in vagasFiltradas" :key="index">
                                    <div class="box-vagas">
                                        <div class="titulo-vaga">
                                            <h5>
                                                {{ vaga.cargo }}
                                            </h5>
                                        </div>
                                        <div class="nome-vaga">
                                            <span>
                                                {{ vaga.nome_empresa }}
                                            </span>
                                        </div>
                                        <div v-if="isExpanded[index]" class="collapse-content">
                                            <div><strong>Telefone:</strong> {{ vaga.numero }}</div>
                                            <div><strong>E-mail:</strong> {{ vaga.email }}</div>
                                        </div>
                                        <button @click="toggleCollapse(index)" class="btn btn-mais p-0">
                                            {{ isExpanded[index] ? 'Ocultar informações' : 'Ver informações' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else>
                            <h3>Infelizmente ainda não temos vagas cadastradas</h3>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    </section>

    <FooterInternasVue />
</template>

<script>

import axios from 'axios';

const urlVAGAS = 'https://api.cref20.org.br/wp-json/cref/v1/vagas';

import FooterInternasVue from '../components/FooterInternas/FooterInternas.vue';
import BreadcrumbVue from '../components/Breadcrumb/Breadcrumb.vue';

export default {
    name: 'VagasPage',
    components: {
        FooterInternasVue,
        BreadcrumbVue
    },
    data() {
        return {
            vagas: [],
            vagasFiltradas: [],
            filtroSelecionado: '',
            isExpanded: {},
        }
    },
    mounted() {
        axios.get(urlVAGAS).then((response) => {
            const data = response.data;
            this.vagas = data.map((vaga) => ({
                id_vagas: vaga.id_vaga,
                slug_vaga: vaga.slug_vaga,
                cargo: vaga.cargo,
                nome_empresa: vaga.nome_empresa,
                numero: vaga.numero_contato,
                email: vaga.email_contato
            }));

            this.vagasFiltradas = this.vagas;
            this.isExpanded = this.vagas.reduce((acc, _, index) => {
                acc[index] = false;
                return acc;
            }, {});
        });
    },
    methods: {
        atualizarLista() {
            const filtro = this.filtroSelecionado.toLocaleLowerCase();

            this.vagasFiltradas = this.vagas.filter((vaga) => {
                return vaga.cargo.toLocaleLowerCase().includes(filtro) || vaga.nome_empresa.toLocaleLowerCase().includes(filtro);
            })
        },
        toggleCollapse(index) {
            if (Object.hasOwn(this.isExpanded, index)) {
                this.isExpanded[index] = !this.isExpanded[index];
            } else {
                this.isExpanded[index] = true;
            }
        }
    }
}
</script>

<style scoped>
.vagas-disponiveis .titulo-cargos {
    color: #212121;
    font-weight: 600;
    margin: 0;
    font-size: 2.2rem;
}

.vagas-disponiveis .cargo {
    font-size: 17px;
    color: #45a047;
    margin: 0;
    font-weight: 700;
}

.box-vagas {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 15px;
    max-height: 200px;
    border: 1px solid #fcd2d2;
    border-radius: 10px;
    gap: 3px;
}

.btn-mais {
    color: #45a047;
    border: none;
    background-color: transparent;
    padding: 0.4rem 2.5rem;
    text-decoration: none;
    font-weight: 700;
    font-size: 1rem;
}
</style>