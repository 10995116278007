<template>
    <CarrosselVue />
    <ServicosHome/>
    <NoticiasVue />
    <MultimidiaVue />
    <SobreHomeVue/>
    <InstagramVue/>
    <FooterHomeVue/>
</template>

<script>

import CarrosselVue from '../components/Carrossel/Carrossel.vue';
import ServicosHome from '../components/ServicosHome/ServicosHome.vue';
import NoticiasVue from '../components/Noticias/Noticias.vue';
import MultimidiaVue from '../components/Multimidia/Multimidia.vue';
import SobreHomeVue from '../components/SobreHome/SobreHome.vue';
import InstagramVue from '../components/Instagram/Instagram.vue';
import FooterHomeVue from '../components/FooterHome/FooterHome.vue';

export default {
    name: 'HomePage',
    components: {
        CarrosselVue,
        ServicosHome,
        NoticiasVue,
        MultimidiaVue,
        SobreHomeVue,
        InstagramVue,
        FooterHomeVue
    }
}
</script>

<style scoped>
@media (max-width: 765px) {
    .noticias {
        margin-top: 110rem;
    }
    .sobre-home {
        height: 160vh;
    }
}
</style>