<template>
    <div class="row mt-5">
        <div class="col-lg-12">
            <div class="titulo-section text-center">
                <h2>
                    Denúncias, dúvidas ou sugestões
                </h2>
            </div>
        </div>
        <div class="col-lg-4 mt-4" v-for="(link, index) in links" :key="index">
            <a :href="link.link" target="_blank">
                <div class="box-duvidas">
                    <div class="infos d-flex align-items-center">
                        <div class="icone">
                            <img :src="link.icone_link" alt="Ícone">
                        </div>
                        <div class="titulo-duvidas ms-3">
                            <span>
                                {{ link.titulo_link }}
                            </span>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

const urlHOME = 'https://api.cref20.org.br/wp-json/cref/v1/home';
export default {
    name: 'DuvidasVue',
    data() {
        return {
            links: []
        }
    },
    mounted() {
        axios.get(urlHOME).then((response) => {
            this.links = response.data[0].corpo.links_rapidos.map((link) => ({
                icone_link: link.icone_link,
                titulo_link: link.titulo_link,
                link: link.link_rapido
            }));
        })
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
.box-duvidas {
    background: #fff;
    border-radius: 10px;
    border: 3px solid rgba(246, 159, 159, .3);
    height: 106px;
    display: flex;
    align-items: center;
    padding: 10px;
}
 .box-duvidas .infos .titulo-duvidas span {
    color: #575757;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
 
</style>