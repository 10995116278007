<template>
    <section class="servicos-home">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 mt-5" v-for="(servico, index) in servicos" :key="index">
                    <a :href="servico.link_servico" target="_blank" v-if="servico.link_servico !== '' && servico.link_servico !== undefined && servico.link_servico !== null">
                        <div class="box-serv">
                            <div class="text-center">
                                <img :src="servico.icone_servico" alt="Ícone">
                                <div class="span text-serv">
                                    <span>
                                        {{ servico.titulo_servico }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </a>
                    <router-link :to="servico.link_interno" v-else-if="servico.link_interno !== ''">
                        <div class="box-serv">
                            <div class="text-center">
                                <img :src="servico.icone_servico" alt="Ícone">
                                <div class="span text-serv">
                                    <span>
                                        {{ servico.titulo_servico }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <TipoPessoaVue/>
            <DuvidasVue />
        </div>
    </section>
</template>

<script>
import TipoPessoaVue from '../TipoPessoa/TipoPessoa.vue';
import DuvidasVue from '../duvidas/Duvidas.vue';

import axios from 'axios';

const urlHOME = 'https://api.cref20.org.br/wp-json/cref/v1/home';

export default {
    name: 'ServicosHome',
    components: {
        TipoPessoaVue,
        DuvidasVue
    },
    data() {
        return {
            servicos: []
        }
    },
    mounted() {
        axios.get(urlHOME).then((response) => {
            this.servicos = response.data[0].corpo.servicos.map((servico) => ({
                titulo_servico: servico.titulo_do_servico,
                icone_servico: servico.icone_servico,
                link_servico: servico.link_servico,
                link_interno: servico.link_interno
            }));
        })
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
.servicos-home {
    background-color: #EEEEEECC;
    width: 100%;
    height: 650px;
    border-bottom-left-radius: 351px 332px;
    border-bottom-right-radius: 351px 332px;
}

.servicos-home .box-serv {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid rgba(246, 159, 159, .3);
}

.box-serv .text-serv {
    color: #575757;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px;
    text-align: center;
    display: block;
}
</style>