<template>
    <BreadcrumbVue/>
    <section class="pessoa-fisica">
        <div class="container">
            <div class="row text-center">
                <div class="col-lg-3" v-for="(pj, index) in pdados" :key="index">
                    <a :href="pj.link_externo" v-if="pj.link_externo !== null && pj.link_externo !== undefined && pj.link_externo !== ''" target="_blank">
                        <div class="box-pf-pj">
                            <div class="icone-pf-pj">
                                <img :src="pj.icone" alt="Ícones">
                            </div>
                            <div class="titulo-pf">
                                <h6>
                                    {{ pj.titulo }}
                                </h6>
                            </div>
                            <div class="link-pf-pj">
                                <a :href="pj.link_externo">
                                    Acessar
                                    <i class="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </a>
                    <router-link :to="{name: 'Leitura Pessoa Jurídica', params: {link: pj.link}}" v-else>
                        <div class="box-pf-pj">
                            <div class="icone-pf-pj">
                                <img :src="pj.icone" alt="Ícones">
                            </div>
                            <div class="titulo-pf">
                                <h6>
                                    {{ pj.titulo }}
                                </h6>
                            </div>
                            <div class="link-pf-pj">
                                <router-link :to="{name: 'Leitura Pessoa Jurídica', params: {link: pj.link}}">
                                    Acessar
                                    <i class="bi bi-arrow-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
    
    
        <FooterInternas/>
    </template>
    
    <script>
    import axios from 'axios';


    import FooterInternas from '../components/FooterInternas/FooterInternas.vue';
    import BreadcrumbVue from '../components/Breadcrumb/Breadcrumb.vue';

    const URLPJURIDICA = 'https://api.cref20.org.br/wp-json/cref/v1/pessoa-juridica';
    
    export default {
        name: 'PessoaJuridicaPage',
        components: {
            FooterInternas,
            BreadcrumbVue
        },
        data() {
            return {
                pdados: []
            }
        },
        mounted() {
            axios.get(URLPJURIDICA).then((response) => (
                this.pdados = response.data.map((pages) => ({
                    titulo: pages.titulo_post,
                    icone: pages.icone_destaque,
                    link: pages.slug_post,
                    link_externo: pages.link_externo
                }))
            ))
        }
    }
    </script>
    
    <style scoped>
    a {
        text-decoration: none;
    }
.pessoa-fisica {
    margin-top: 15px;
}
.pessoa-fisica .box-pf-pj {
    border: 3px solid #E0EDE0;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 10px;
    height: 252px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.pessoa-fisica .box-pf-pj .titulo-pf h6 {
    color: #575757;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.pessoa-fisica .box-pf-pj .link-pf-pj a {
    color: #5e5e5e;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    border-bottom: 1px solid #5D5D5D;
}
.pessoa-fisica .box-pf-pj .link-pf-pj a .bi-arrow-right {
    color: #45A047;
}
     
    </style>