<template>
    <BreadcrumbVue/>
 <section class="forms">
    <div class="container">
        <div class="informacoes">
            <div class="row">
                <div class="col-lg-3" v-for="(info, index) in contato.informacoes" :key="index">
                    <div class="box-info">
                        <div class="icon">
                            <img :src="require('@/assets/imgs/phone-contato.png')" alt="">
                        </div>
    
                        <div class="titulo-info my-4">
                            <h5>
                                Atendimento por Telefone
                            </h5>
                        </div>
    
                        <div class="contato-info">
                            <span>
                                {{ info.telefone_atendimento }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3" v-for="(info, index) in contato.informacoes" :key="index">
                    <div class="box-info">
                        <div class="icon">
                            <img :src="require('@/assets/imgs/email-contato.png')" alt="">
                        </div>
    
                        <div class="titulo-info my-4">
                            <h5>
                                Atendimento por E-mail
                            </h5>
                        </div>
    
                        <div class="contato-info">
                            <span>
                                {{ info.email_atendimento }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3" v-for="(info, index) in contato.informacoes" :key="index">
                    <div class="box-info">
                        <div class="icon">
                            <img :src="require('@/assets/imgs/endereco-contato.png')" alt="">
                        </div>
    
                        <div class="titulo-info my-4">
                            <h5>
                                Nosso Endereço
                            </h5>
                        </div>
    
                        <div class="contato-info">
                            <span>
                                {{ info.endereco }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3" v-for="(info, index) in contato.informacoes" :key="index">
                    <div class="box-info">
                        <div class="icon">
                            <img :src="require('@/assets/imgs/horario-contato.png')" alt="contato">
                        </div>
    
                        <div class="titulo-info my-4">
                            <h5>
                                Horário de Atendimento
                            </h5>
                        </div>
    
                        <div class="contato-info">
                            <span>
                                {{ info.horario }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="formularios">
            <div class="row">
                <div class="col-lg-6">
                    <div class="titulo-contato" v-for="(form, index) in contato.formulario" :key="index">
                        <h2>
                            {{ form.titulo }}
                        </h2>
                    </div>
    
                    <div class="filtro-forms">
                        <ul role="list">
                            <li role="listitem">
                                <a href="javascript:;" @click="selectForm = 'contato'" :class="{active: selectForm === 'contato'}">
                                    Envie-nos uma mensagem
                                </a>
                            </li>
                            <li role="listitem">
                                <a href="javascript:;" @click="selectForm = 'ouvidoria'" :class="{active: selectForm === 'ouvidoria'}">
                                    Falar com a Ouvidoria
                                </a>
                            </li>
                            <!-- <li role="listitem">
                                <a href="javascript:;" @click="selectForm = 'trabalhe-conosco'" :class="{active: selectForm === 'trabalhe-conosco'}">
                                    Trabalhe Conosco
                                </a>
                            </li> -->
                        </ul>
                    </div>
                </div>
    
                <div class="col-lg-6" v-show="selectForm === 'contato'">
                    <div class="text-center">
                        <h3>
                            Envie-nos uma mensagem
                        </h3>
                    </div>
                    <form @submit="onSubmit">
                        <div class="row">
                            <div class="col-lg-12">
                                <input type="text" class="form-control" id="nome" placeholder="Nome" v-model="nome">
                            </div>
                            <div class="col-lg-6">
                                <input type="email" class="form-control" name="email" id="email" placeholder="E-mail" v-model="email">
                            </div>
                            <div class="col-lg-6">
                                <input type="number" class="form-control" name="telefone" id="telefone" placeholder="Telefone" v-model="telefone">
                            </div>
                            <div class="col-lg-12">
                                <input type="text" name="assunto" id="assunto" class="form-control" placeholder="Assunto" v-model="assunto">
                            </div>
                            <div class="col-lg-12">
                                <textarea class="form-control" id="textarea" name="mensagem" rows="3" placeholder="Mensagem" v-model="mensagem"></textarea>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="termos-de-uso" v-model="termos">
                                    <label class="form-check-label" for="termos-de-uso">
                                      Li e aceito os termos de <a routerLink="/politica-de-privacidade">
                                        Política de privacidade
                                      </a>
                                      .
                                    </label>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <input type="submit" value="Enviar">
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-lg-6" v-show="selectForm === 'ouvidoria'">
                    <div class="text-center">
                        <h3>
                            Ouvidoria
                        </h3>
                    </div>
                    <form action="">
                        <div class="row">
                            <div class="col-lg-12">
                                <input type="text" class="form-control" name="nome" id="nome" placeholder="Nome">
                            </div>
                            <div class="col-lg-6">
                                <input type="email" class="form-control" name="email" id="email" placeholder="E-mail">
                            </div>
                            <div class="col-lg-6">
                                <input type="number" class="form-control" name="telefone" id="telefone" placeholder="Telefone">
                            </div>
                            <div class="col-lg-12">
                                <input type="text" name="assunto" id="assunto" class="form-control" placeholder="Assunto">
                            </div>
                            <div class="col-lg-12">
                                <textarea class="form-control" id="textarea" name="mensagem" rows="3" placeholder="Mensagem"></textarea>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="termos-de-uso">
                                    <label class="form-check-label" for="termos-de-uso">
                                      Li e aceito os termos de <a routerLink="/politica-de-privacidade">
                                        Política de privacidade
                                      </a>
                                      .
                                    </label>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <input type="submit" value="Enviar">
                            </div>
                        </div>
                    </form>
                </div>

                <!-- <div class="col-lg-6" v-show="selectForm === 'trabalhe-conosco'">
                    <form action="">
                        <div class="row">
                            <div class="col-lg-12">
                                <input type="text" class="form-control" name="nome" id="nome" placeholder="Nome">
                            </div>
                            <div class="col-lg-6">
                                <input type="email" class="form-control" name="email" id="email" placeholder="E-mail">
                            </div>
                            <div class="col-lg-6">
                                <input type="number" class="form-control" name="telefone" id="telefone" placeholder="Telefone">
                            </div>
                            <div class="col-lg-12">
                                <label for="curriculo">Currículo</label>
                                <input type="file" name="curriculo" id="curriculo" class="form-control" placeholder="Curriculo">
                            </div>
                            <div class="col-lg-12">
                                <textarea class="form-control" id="textarea" name="mensagem" rows="3" placeholder="Mensagem"></textarea>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="termos-de-uso">
                                    <label class="form-check-label" for="termos-de-uso">
                                      Li e aceito os termos de <a routerLink="/politica-de-privacidade">
                                        Política de privacidade
                                      </a>
                                      .
                                    </label>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <input type="submit" value="Enviar">
                            </div>
                        </div>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="1411" height="216" viewBox="0 0 1411 216" fill="none" preserveAspectRatio="none">
        <path d="M1414.09 0.387323L0.368164 0.387817L0.368169 15.578C459.524 276.15 918.681 287.835 1414.09 15.5775L1414.09 0.387323Z" fill="#F1F1F1"/>
    </svg>
</section>

<section class="contato-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="titulo-contato">
                    <h2>
                        Nossa Localização
                    </h2>
                </div>
            </div>
            <div class="col-lg-6">
                <ul class="link-maps" role="list">
                    <li role="listitem" v-for="(loc, index) in contato.localizacao"  :key="index">
                        <a :href="loc.link_maps" v-if="loc.link_maps != ''">
                            <img :src="require('@/assets/imgs/maps.png')" alt="Maps">
                            Abrir com o Google Maps
                        </a>
                    </li>
                    <li role="listitem" v-for="(loc, index) in contato.localizacao"  :key="index">
                        <a :href="loc.link_waze" v-if="loc.link_waze != ''">
                            <img :src="require('@/assets/imgs/waze.png')" alt="Maps">
                            Abrir com o Waze
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container-fluid mt-4">
        <div class="row" v-for="(map, index) in contato.localizacao" :key="index">
            <span v-html="map.iframe_map"></span>
        </div>
    </div>
</section>   

<FooterInternas/>
</template>

<script>
import axios from 'axios';

import FooterInternas from '../components/FooterInternas/FooterInternas.vue';
import BreadcrumbVue from '../components/Breadcrumb/Breadcrumb.vue';

const CONTATOURL = 'https://api.cref20.org.br/wp-json/cref/v1/contato'
export default {
    name: 'ContatoPage',
    components: {
        FooterInternas,
        BreadcrumbVue
    },
    data() {
        return {
            selectForm: 'contato',
            contato: [],
            nome: "",
            email: "",
            telefone: "",
            assunto: "",
            mensagem: "",
            termos: false 
        }
    },
    mounted() {
        axios.get(CONTATOURL).then((response) => {
            this.contato = {
                informacoes: response.data[0].corpo.informacoes.map((info) => ({
                    telefone_atendimento: info.telefone_atendimento,
                    link_telefone: info.link_telefone,
                    email_atendimento: info.email_atendimento,
                    endereco: info.endereco,
                    horario: info.horario_funcionamento
                })),
                formulario: response.data[0].corpo.formulario.map((form) => ({
                    titulo: form.titulo_contato
                })),
                localizacao: response.data[0].corpo.localizacao.map((loc) => ({
                    link_waze: loc.link_waze,
                    link_maps: loc.link_maps,
                    iframe_map: loc.iframe_maps
                }))
            }
        })
    }
}
</script>

<style scoped>
.forms {
    background-color: #f1f1f1;
}
.forms .informacoes .box-info {
    border-radius: 15px;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
    padding: 60px 18px;
    text-align: center;
    background-color: #fff;
    height: 295px;
}
.forms .informacoes .box-info .titulo-info h5 {
    color: #5f5e5e;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.forms .informacoes .box-info .contato-info span {
    color: #6c6e71;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}
.forms .formularios {
    margin-top: 3.5rem;
}
.forms .formularios .titulo-contato h2 {
    color: #393939;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
}
    .forms .formularios .filtro-forms ul {
        list-style: none;
        padding: 0;
}
.forms .formularios .filtro-forms ul li {
    margin: 20px 0;
}
.forms .formularios .filtro-forms ul li a {
    text-decoration: none;
    border: 1px solid #45A047;
    padding: 10px;
    width: 267px;
    display: block;
    text-align: center;
    color: #fff;
    border-radius: 50px;
    color: #403e3e;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}
.forms .formularios .filtro-forms ul li a.active {
    background-color: #45A047;
    color: #fff;
}
.forms .formularios .filtro-forms ul li a:hover {
    background-color: #45A047;
    color: #fff;
    transition: 0.3s;
}
.forms .formularios form .form-control {
    height: 5vh;
    margin: 5px 0;
}
.forms .formularios form textarea {
    height: 15vh !important;
}
.forms .formularios form .form-check label {
    color: #575757;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
/* 15.4px */
}
.forms .formularios form input[type="submit"] {
    margin-top: 10px;
    background-color: #45A047;
    border: none;
    padding: 10px 20px;
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 50px;
}
section.forms {
    position: relative;
    top: -207px;
}
.breadcrumb-topo {
    position: relative;
    z-index: 10;
}
.informacoes .row {
    position: relative;
    z-index: 11;
    margin-top: 50px;
}
.titulo-contato h2 {
    color: #393939;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
}
.contato-page {
    margin-top: 4rem;
}
.contato-page .link-maps {
    display: flex;
    list-style: none;
    align-items: center;
}
.contato-page .link-maps li {
    margin: 0 10px;
}
.contato-page .link-maps li a {
    border-radius: 10px;
    border: 1px solid #cdcdcd;
    background: #fff;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
    color: #5e5e5e;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}
svg {
    width: 100%;
    position: relative;
    top: 208px;
}
@media (max-width: 1390px) {
    .contato-page .link-maps li a {
    font-size: 15px;
}
}
@media (max-width: 765px) {
    svg {
        display: none;
	}
}
 
</style>