<template>
  <keep-alive>
    <HeaderVue/>
  </keep-alive>
    <router-view />
</template>

<script>
import HeaderVue from './components/Header/Header.vue'

export default {
  name: 'App',
  components: {
    HeaderVue
  }
}
</script>

<style>
  * {
    font-family: 'Roboto' !important;
  }
</style>
