<template>
   <section class="video-interna">
        <div class="container">
                <div class="row" v-for="(video, index) in videos" :key="index">
                    <div class="col-lg-12">
                        <div class="video-iframe" v-html="video.video"></div>
                    </div>

                    <div class="col-lg-12">
                        <div class="box-compartilhar my-4">
                            <ul class="list-redes" role="list">
                                <li role="listitem" class="titulo-list">
                                    Compartilhar esse post: 
                                </li>
                                <li role="listitem">
                                    <a :href="'https://www.facebook.com/sharer/sharer.php?&amp;u='+ this.urlFinal" target="_blank">
                                        <img :src="require('@/assets/imgs/icon-facebook.png')" alt="FB Icon">
                                    </a>
                                </li>
                                <li role="listitem">
                                    <a :href="'https://twitter.com/home?' + this.urlFinal" target="_blank">
                                        <i class="bi bi-twitter"></i>
                                    </a>
                                </li>
                                <li role="listitem">
                                    <a :href="'https://wa.me/?text=' + this.urlFinal" target="_blank">
                                        <i class="bi bi-whatsapp"></i>
                                    </a>
                                </li>
                                <li role="listitem">
                                    <a :href="'mailto:?' + this.urlFinal" target="_blank">
                                        <i class="bi bi-envelope"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
        </div>
   </section>
   <FooterInternasVue/>
</template>

<script>
import axios from 'axios';
import FooterInternasVue from '@/components/FooterInternas/FooterInternas.vue';



export default {
    name: 'LeituraVideos',
    components: {
        FooterInternasVue
    },
    data() {
        return {
            videos: [],
            urlFinal: ''
        }
    },
    watch: {
        $route() {
            this.fetchDadosVideos();
            this.scrollTop();
        }
    },
    methods: {
        fetchDadosVideos() {
            const VIDEO = 'https://api.cref20.org.br/wp-json/cref/v1/video/' + this.$route.params.video;

            axios.get(VIDEO).then((response) => {
                this.videos = response.data.map((video) => ({
                    video: video.iframe
                }))
            });
        },

        scrollTop() {
            window.scrollTo(0,0);
        }
    },  
    created() {
        this.urlFinal = window.location.href;
        this.fetchDadosVideos();
        this.scrollTop();
    }
}
</script>
<style scoped>
.video-iframe iframe{
    width: 100% !important;
    border-radius: 10px;
}
.titulo-sec {
    color: #393939;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
}
.carousel img {
    height: 600px;
    object-fit: cover;
    border-radius: 40px;
} 
.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: #45A047;
    padding: 25px;
    border-radius: 50px;
}
.box-compartilhar .list-redes a {
    height: 70px;
    width: 70px;
    border: 1px solid #D1D1D1;
    padding: 20px;
    border-radius: 50px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
}
 .redes-sociais li a i {
    color: #45A047;
}

 .box-compartilhar {
    border-radius: 10px;
    background: #F1F1F1;
    padding: 20px;
}
.box-compartilhar .list-redes {
    display: flex;
    list-style: none;
    align-items: center;
    margin-top: 10px;
    padding: 0;
}
.box-compartilhar .list-redes li .titulo-list {
    color: #747474 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;    
}
.box-compartilhar .list-redes li {
    margin: 0 10px;
}
.box-compartilhar .list-redes a {
    height: 50px;
    width: 50px;
}
.box-compartilhar .list-redes li a i {
    color: #45A047;
}
</style>