<template>
    <section class="footer-home">
    <footer>
        <div class="container">
            <div class="row geral">
                <div class="col-lg-4">
                    <a href="tel:">
                        <div class="titulo-footer">
                            <h6>
                                Central de atendimento
                            </h6>
                        </div>
                        <div class="tel-atendimento">
                            <img src="../../assets/imgs/telicon.png" alt="Ícone telefone" class="me-2">
                            <span>
                                (79) 3214-6184
                            </span>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4">
                    <a href="">
                        <div class="titulo-footer">
                            <h6>
                                Nosso Endereço
                            </h6>
                        </div>
                        <div class="tel-atendimento">
                            <img src="../../assets/imgs/endereco.png" alt="Ícone telefone" class="me-2">
                            <span class="normal">
                                Rua José de Faro Rollemberg, 380 - Salgado Filho, Aracaju - SE, 49020-020
                            </span>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4">
                    <a href="mailto:">
                        <div class="titulo-footer">
                            <h6>
                                E-mail
                            </h6>
                        </div>
                        <div class="tel-atendimento">
                            <img src="../../assets/imgs/email.png" alt="Ícone telefone" class="me-2">
                            <span class="normal">
                                cref20@cref20.org.br
                            </span>
                        </div>
                    </a>
                </div>
                

                <div class="col-lg-4 mt-5">
                    <div class="logo">
                        <router-link to="/">
                            <img src="../../assets/imgs/logo_cref.webp" alt="Logo CREF">
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 mt-5">
                    <div class="titulo-footer mb-2">
                        LINKS RÁPIDOS
                    </div>

                    <ul class="links-rapidos">
                        <div class="row">
                            <!-- <div class="col-lg-6">
                                <li>
                                    <router-link to="/institucional">
                                        Institucional
                                    </router-link>
                                </li>
                            </div> -->
                            <div class="col-lg-6">
                                <li>
                                    <router-link to="/legislacao">
                                        Legislação
                                    </router-link>
                                </li>
                            </div>
                            <!-- <div class="col-lg-6">
                                <li>
                                    <router-link to="/fiscalizacao">
                                        Fiscalização
                                    </router-link>
                                </li>
                            </div> -->
                            <div class="col-lg-6">
                                <li>
                                    <router-link to="/noticias">
                                        Notícias
                                    </router-link>
                                </li>
                            </div>
                            <div class="col-lg-6">
                                <li>
                                    <router-link to="/contato">
                                        Contato
                                    </router-link>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>

                <div class="col-lg-4 mt-5">
                    <div class="titulo-footer mb-2">
                        SERVIÇOS
                    </div>

                    <ul class="links-rapidos">
                        <div class="row">
                            <!-- <div class="col-lg-6">
                                <li>
                                    <router-link to="/institucional">
                                        Agendar Atendimento
                                    </router-link>
                                </li>
                            </div> -->
                            <div class="col-lg-6">
                                <li>
                                    <router-link to="/contato">
                                        Academias Registradas
                                    </router-link>
                                </li>
                            </div>
                            <div class="col-lg-6">
                                <li>
                                    <router-link to="">
                                        Primeiro Registro
                                    </router-link>
                                </li>
                            </div>
                            <div class="col-lg-6">
                                <li>
                                    <router-link to="">
                                        Busca de Profissionais
                                    </router-link>
                                </li>
                            </div>
                            <div class="col-lg-6">
                                <li>
                                    <router-link to="">
                                        Retire seu boleto
                                    </router-link>
                                </li>
                            </div>
                            <div class="col-lg-6">
                                <li>
                                    <router-link to="/servicos">
                                        Mais Serviços
                                    </router-link>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>

        <div class="alfamaweb">
            <hr>
            <div class="container">
                <span class="copy">
                    Copyright © 2023 - Todos os direitos reservados à 
                   <strong>CREF20/SE</strong>.
                </span>
    
                <a href="https://alfamaweb.com.br">
                    <img src="../../assets/imgs/logo-alfama.png" alt="Logo alfama web">
                </a>
            </div>
        </div>
    </footer>
</section>
<BtnWhatsapp/>
</template>

<script>
import BtnWhatsapp from '@/components/WhatsappBtn/Whatsapp.vue';
export default {
    name: 'FooterInternas',
    components: {
        BtnWhatsapp
    }
}
</script>

<style scoped>
.footer-home .container .row.geral {
    margin-top: 120px;
}
.footer-home footer a {
    text-decoration: none;
}
.footer-home footer .titulo-footer {
    color: #12575F;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.footer-home footer span {
    color: #575757;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.footer-home footer span.normal {
    color: #575757;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.footer-home footer .links-rapidos {
    list-style: none;
    padding: 0;
}
.footer-home footer .links-rapidos a {
    color: #5e5e5e;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 190%;
/* 22.8px */
    text-transform: uppercase;
}
.footer-home footer .links-rapidos a::after {
    content: "";
    display: block;
    width: 151px;
    height: 1px;
    background-color: #d9d9d9;
    margin-top: 7px;
}
.footer-home footer .alfamaweb .copy {
    color: #4d4d4d;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 190%;
}
.footer-home footer .alfamaweb .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 765px) {
    .alfamaweb .container {
        flex-direction: column;
    }
}
 
</style>